<template>
  <div class="login_wrap">
    <div class="logo_wrap">
      <img :src="logoUrl" alt="">
    </div>
    <h2 class="school_title">{{title}}</h2>
    <form class="login_form">
      <div class="input_wrap">
        <input type="text" v-model="mobile" class="login_input" placeholder="请输入用户名" onfocus="this.placeholder=''" onblur="this.placeholder='请输入用户名'" >
        <input type="password" v-model="password" class="login_input" placeholder="请输入密码" onfocus="this.placeholder=''" onblur="this.placeholder='请输入密码'" >
      </div>
      <el-button type="primary" class="submit_btn" @click="onSubmit">登录</el-button>
    </form>
  </div>
</template>

<script>
import apis from "../../api/request.js";
import logoUrl from "../../../static/images/logo.png";

export default {
  data() {
    return {
      title: "闲画艺术课堂",
      logoUrl: logoUrl,
      mobile: '',
      password: '',
      center: {lng: 0, lat: 0},  //经纬度
      loginForm:{
        longitude:"121.47010842679067",//经度
        latitude:"31.23901439938865",//纬度
      }
    };
  },
  mounted() {
    var mapObj = new AMap.Map('iCenter');
	var _this = this
      mapObj.plugin('AMap.Geolocation', function () {
        let geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, // 是否使用高精度定位，默认:true
          timeout: 10000,           // 超过10秒后停止定位，默认：无穷大
          maximumAge: 0,            // 定位结果缓存0毫秒，默认：0
          convert: true,            // 自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true,         // 显示定位按钮，默认：true
          buttonPosition: 'LB',     // 定位按钮停靠位置，默认：'LB'，左下角
          buttonOffset: new AMap.Pixel(10, 20), // 定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          showMarker: true,         // 定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true,         // 定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true,      // 定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy:true       // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        });
        mapObj.addControl(geolocation);
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, 'complete', onComplete); // 返回定位信息
        AMap.event.addListener(geolocation, 'error', onError);       // 返回定位出错信息
      });
      function onComplete(obj){
			const bd_lat_lon = []
			bd_lat_lon.push(obj.position.lat)
			bd_lat_lon.push(obj.position.lng)
			const a = 3.14159265358979324 * 3000.0 / 180.0;
			const x = bd_lat_lon[1], y = bd_lat_lon[0];
			const z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * a);
			const theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * a);
			bd_lat_lon[0] = z * Math.cos(theta) + 0.0065;
			bd_lat_lon[1] = z * Math.sin(theta) + 0.006;
			_this.loginForm.longitude = bd_lat_lon[0]
			_this.loginForm.latitude = bd_lat_lon[1]
			console.log('217', _this.loginForm)		
      }
      function onError(obj) {
        console.log('err' ,obj);
        _this.loginForm.longitude = ''
        _this.loginForm.latitude = ''
      }
  },
  methods: {
    onSubmit(values) {
      if (!this.mobile) {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: "<span style='font-size:25px;color:red'>用户名不能为空哦</span>"
        });
        return;
      }
      if (!this.password) {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: "<span style='font-size:25px;color:red'>密码不能为空哦</span>"
        });
        return;
      }
      if (!this.loginForm.latitude) {
        alert('请开启手机定位')
        return ;
      }
      let postData = {
          mobile: this.mobile,
          password: this.password,
          latitude: this.loginForm.latitude,
          longitude: this.loginForm.longitude,
          uuid: this.$route.params.uuid
      }
      this.$post(apis.getLogin, postData).then(res=>{
            this.afterLogin(res)//登录事件
        }).catch(err=>{
            console.log(err);
        })
    
    },
    afterLogin(res) {//登录存储信息
        this.subLoading = false
        if(res.code === 0){
            let info = res.data.user
            localStorage.setItem('userName', info.real_name);
            localStorage.setItem('avatar', info.avatar);//头像
            localStorage.setItem('userId', info.id);
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('userMobile', info.mobile);
            localStorage.setItem('schoolId', info.school_id);
            localStorage.setItem('schoolType', res.data.organization.school_type)
            // 1、幼儿园 2、小学
            // localStorage.setItem('ageSection', 2);
            if(res.data.organization.school_type == 1){
                localStorage.setItem('ageSection', 3);
            }
            if(res.data.organization.school_type == 2){
                localStorage.setItem('ageSection', 1);
            }
            // headers
            // this.$axios.defaults.headers.common['Authorization'] = res.data.token;
            this.$router.push("/home")
        }
        if(res.code !== 0){
            // this.$message.error(res.message)
            this.$message({
              dangerouslyUseHTMLString: true,
              message: "<span style='font-size:25px;color:red'>" + res.message + "</span>"
            });
            return
        }
    }
  },
};
</script>

<style scoped>
  .login_wrap {
    padding-top: 25%;
  }
  .school_title {
    margin-top: 0px;
  }
  .login_form {
  }
  .input_wrap {
    margin-bottom: 75px;
    margin-top: 70px;
  }
  .login_wrap .login_input {
    width: 60%;
    height: 80px;
    line-height: 80px;
    display: flex;
    margin: 0 auto;
    border: none;
    border-bottom: 2px solid black;
    margin-bottom: 36px;
    font-size: 30px;
    padding-left: 5px;
  }
  .submit_btn {
    width: 60%;
    height: 70px;
    font-size: 36px;
  }
</style>
